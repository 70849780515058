<template>
  <!-- 新闻动态 -->
  <div id="news">
    <div class="pageCont container tl">
      <bread class="nav" :title="title" :subtitle="subtitle"></bread>
      <el-divider></el-divider>
      <div v-loading="loading" element-loading-text="拼命加载中">
        <el-tabs v-model="activeName" @tab-click="toChangeJYLB">
          <!-- <el-tab-pane label="全部" name="first"> -->
          <el-tab-pane
            v-for="(item,index) in navlists"
            :key="index"
            :label="item.name"
            :name="item.code.toString()"
          >
            <div class="notice-cont" style="min-heigt:400px;" v-if="showData">
              <ul class="contUl">
                <li
                  class="contLi"
                  v-for="(item,index) in lists"
                  :key="index"
                  @click="lookDetail(item.data_id)"
                >
                  <p class="news text-18 text-bold">{{item.title}}</p>
                  <p class="date text-14">
                    <img style="margin:-2px 5px 0 0;" src="~@/assets/images/time.png" alt>
                    {{item.createtime | filterTime}}
                  </p>
                </li>
              </ul>
            </div>
            <div v-else class="margin-top" style="min-height:300px;">暂无数据！</div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="tableParams.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import bread from "@/components/breadcrumb.vue";
import { all } from "q";
export default {
  data() {
    return {
      loading: false,
      title: "新闻动态",
      subtitle: "",
      activeName: "0",
      navlists: [],
      lists: [],
      tableParams: {
        pageSize: 10,
        pageCurrent: 1,
        total: null
      },
      showData: true
    };
  },

  created() {
    this.initZDB();
    this.getData(0);
  },
  methods: {
    // 查询字典表--新闻动态标题
    initZDB() {
      let param = {
        dcode: "xwdttype"
      };
      this.apis.api.getSerchZDB(param).then(res => {
        if (res.data.code == "01") {
          let datas = res.data.dat;
          // tab添加第一项“全部”
          this.navlists = datas;
          this.navlists.unshift({
            name: "全部",
            code: 0
          });
        }
      });
    },

    // 获取数据
    getData(val) {
      let param = {
        xwdttype: val,
        pageNum: this.tableParams.pageCurrent,
        pageSize: this.tableParams.pageSize
      };
      this.loading = true;
      this.apis.api.getNewsList(param).then(res => {
        if (res.data.code == "01") {
          let datas = res.data.dat;
          this.loading = false;

          this.lists = datas;

          this.tableParams.total = res.data.count;

          if (datas == "" || datas == null || datas == undefined) {
            this.showData = false;
          } else {
            this.showData = true;
          }
        }
      });
    },

    // 切换新闻动态类别
    toChangeJYLB(tab, event) {
      this.getData(tab.index);
    },

    // 跳转到详情页面
    lookDetail(item) {
      this.$router.push({
        name: "noticeDetail",
        query: {
          id: item,
          child: "新闻动态",
          path: "/news"
        }
      });
      // scrollTo(0, 0);
      // document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    // 切换分页
    sizeChange(val) {
      var _this = this;
      _this.tableParams.pageSize = val;
      //方法  传参
      _this.getData(0);
      //方法  传参
    },
    // 分页
    currentChange(val) {
      var _this = this;
      _this.tableParams.pageCurrent = val;
      _this.getData(0);
    }
  },
  components: {
    bread
  }
};
</script>
<style lang="scss" scoped>
#news {
  .pageCont {
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    .notice-cont {
      // width: calc(100% - 180px);
      .contUl {
        .contLi {
          // display: flex;
          // justify-content: space-between;
          height: 85px;
          font-size: 16px;
          border-bottom: 1px solid #ccc;
          .news {
            padding: 20px 0 6px;
            box-sizing: border-box;
            width: 1000px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #000;
            cursor: pointer;
            &:hover {
              color: #02a7f0;
            }
          }
          .date {
            color: #aaaaaa;
            // &:hover {
            //   color: #0e83c9;
            // }
          }
        }
      }
    }
    .pagination {
      padding-top: 20px;
      height: 55px;
    }
  }
  // 分割线
  /deep/.el-divider--horizontal {
    margin: 0 0 10px;
  }

  /deep/.el-tabs__item {
    margin: 5px 0;
    font-size: 16px;
  }
}
</style>

